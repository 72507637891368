;<template>
  <div class="pay-method-wrap px-1 py-2">
    <div v-for="(item, index) in list" :key="index" class="pay-method-img-wrap cursor-pointer pa-1" :class="{ 'selected': selectedValue == item.value }" @click="selectedValue = item.value">
      <img class="pay-method-img" :src="getImg(item)" :alt="`pg_${item.value}`">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    getImg(item) {
      let img;
      try {
        img = require(`./assets/pg_${item.value}.png`);
      } catch (error) {
        img = null;
      }
      return img;
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../styles/mixin.scss';
.pay-method-wrap {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(8, 1fr);
  background-color: #fff;
  border-radius: 4px;
  @include for-md {
    grid-template-columns: repeat(6, 1fr);
  }
  @include for-sm {
    grid-template-columns: repeat(3, 1fr);
  }
}
.pay-method-img-wrap {
  &.selected {
    border: 2px solid #383838;
    border-radius: 4px;
  }
}
.pay-method-img {
  max-width: 100%;
}
</style>