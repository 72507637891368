<template>
  <div v-if="order" class="d-flex flex-column h-100 px-2">
    <div class="d-flex align-center justify-center mb-3">
      <v-icon class="mr-2" color="var(--red-common)" size="50">mdi-close-circle-outline</v-icon>
      <div class="page-title">Payment Failed</div>
    </div>
    <div class="order-wrap pa-3 mb-3">
      <div class="d-flex justify-space-between mb-2">
        <div class="font-1dot25r font-weight-bold">Order Details</div>
        <div class="font-1dot25r font-weight-bold">{{ order.cdate ? setTimeZone(order.cdate, timeFormat) : '-' }}</div>
      </div>
      <v-divider class="my-2"></v-divider>

      <ReceiptSummary :order="order" />
    </div>
    <div v-if="isTakeaway" class="order-wrap pa-3 mb-6">
      <div class="font-1dot25r font-weight-bold font-red-common text-center">
        * Please retry or pay at counter. *
      </div>
    </div>
    <div class="d-flex flex-column align-center justify-center font-1dot25r mb-2">
      <AppBtn class="btn-black mb-2" block @click="retryModalShow = true">Retry payment</AppBtn>
      <template v-if="isTakeaway">
        <span class="mb-2">or</span>
        <AppBtn class="btn-black" block :loading="payCounterLoading" @click="payAtCounter">Pay at counter</AppBtn>
      </template>
    </div>
    <RetryPaymentModal v-model="retryModalShow" :loading="retryLoading" @confirm="retryPayment" />
    <PaymentForm ref="paymentForm" :data="pgFormData" />
  </div>
</template>
<script>
import { DELIVERY_CHANNEL } from '@/common/enum/checkout';
import { orderApi, orderPayApi } from '@/api/order';
import PaymentForm from '../form';
import RetryPaymentModal from './retry-payment-modal';
import ReceiptSummary from '@/views/Order/receipt-summary';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  name: 'OrderPaymentFailed',
  components: {
    PaymentForm,
    RetryPaymentModal,
    ReceiptSummary,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      retryLoading: false,
      payCounterLoading: false,
      retryModalShow: false,
      pgFormData: {
        actionUrl: '',
        merchantId: '',
        amount: '',
        orderId: '',
        billName: '',
        billEmail: '',
        billMobile: '',
        billDesc: '',
        country: '',
        vcode: '',
        currency: '',
        channel: '',
        returnurl: '',
        callbackurl: '',
      },
    };
  },
  computed: {
    ...mapState({
      timeFormat: state => state.app.timeFormat,
    }),
    ...mapGetters(['getOrderHistory', 'getUserDisplayName', 'getMobileNo', 'getEmail', 'getOrderUserId']),
    isTakeaway() {
      return this.order?.channel == DELIVERY_CHANNEL.TAKEAWAY;
    },
    isDelivery() {
      return this.order?.channel == DELIVERY_CHANNEL.DELIVERY;
    },
    subtotal() {
      if (this.isUndefinedNullOrEmpty(this.order.deliveryFee)) return this.priceFormat(this.order.amt);
      const nettDeliveryFee = this.order.deliveryFee - this.order.discount;
      return this.priceFormat(this.order.amt - nettDeliveryFee);
    },
    deliveryFee() {
      return !this.isUndefinedNullOrEmpty(this.order.deliveryFee)
        ? this.priceFormat(this.order.deliveryFee)
        : this.priceFormat(0);
    },
    deliveryFeeAfterDisc() {
      if (this.isUndefinedNullOrEmpty(this.order.discount)) return this.deliveryFee;
      return this.priceFormat(this.deliveryFee - this.order.discount);
    },
  },
  methods: {
    ...mapActions(['updateOrderHistory']),
    retryPayment(paymentMethod) {
      const data = {
        orderId: this.$route.query.orderId,
        orderUserId: this.getOrderUserId,
        amt: this.order.amt,
        remarks: 'Retry payment',
        payMethod: paymentMethod,
        payOpt: null, // 0-No Save Card, 1-Save Card
      };
      this.retryLoading = true;

      return orderPayApi
        .payOnline(data)
        .then(res => {
          this.postToPg(res.data);
        })
        .catch(() => {
          this.retryLoading = false;
        });
    },
    payAtCounter() {
      const data = {
        orderId: this.order.id,
        orderUserId: this.getOrderUserId,
        amt: this.order.amt,
        remarks: 'Pay at counter',
      };
      this.payCounterLoading = true;
      return orderPayApi
        .payCounter(data)
        .then(async () => {
          // const orderRes = await this.getOrderSeq(data.orderId, data.orderUserId);
          // let order = this.getOrderHistory.find(item => item.orderId == this.order.orderId);

          // order.seq = orderRes.data.seq;
          // order.status = orderRes.data.status;
          // order.curSeq = orderRes.data.curSeq;
          // this.updateOrderHistory(order);

          this.navigateReplace('OrderDetails', { orderId: this.$route.query.orderId });
          this.payCounterLoading = false;
        })
        .catch(() => {
          this.payCounterLoading = false;
        });
    },
    postToPg(data) {
      this.pgFormData.actionUrl = data.url;
      this.pgFormData.merchantId = data.merchantId;
      this.pgFormData.amount = this.priceFormat(this.order.amt); // Need to pass .00 to Razer . Eg. 1.00, 2.00
      this.pgFormData.orderId = data.orderNo;
      this.pgFormData.billName = this.getUserDisplayName;
      this.pgFormData.billEmail = this.getEmail;
      this.pgFormData.billMobile = this.getMobileNo;
      this.pgFormData.country = data.country;
      this.pgFormData.vcode = data.vcode;
      this.pgFormData.currency = data.curr;
      this.pgFormData.channel = data.channel;

      this.$nextTick(() => {
        this.$refs['paymentForm'].postToPg();
      });
    },
    getOrderSeq(orderId, userId) {
      const params = {
        id: orderId,
        userId,
      };
      return orderApi.getSeq(params).then(res => {
        return res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-title {
  color: var(--primary-black-color);
  font-size: 1.5rem;
  font-weight: 600;
}
.order-wrap {
  background-color: #fff;
  border: 5px solid var(--primary-black-color);
  border-radius: 5px;
}
</style>
