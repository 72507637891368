<template>
  <CommonModal v-model="show" :modal-size="'modal-sm'" :title="'Retry Payment'" :footer="false">
    <template #body>
      <div class="pa-3">
        <PaymentChannel class="mb-1" v-model="form.payMethod" :list="onlinePayMethodList" />
        <AppBtn class="btn-black" block :loading="loading" @click="payNow">Pay now</AppBtn>
      </div>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/Modal';
import PaymentChannel from '../channel';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'RetryPaymentModal',
  components: {
    CommonModal,
    PaymentChannel,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      form: {
        payMethod: null,
      },
      options: this.$t('opt.deliveryChannel'),
    }
  },
  computed: {
    ...mapState({
      onlinePayMethodList: state => state.lookup.onlinePayMethodList,
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.onlinePayMethodList)) this.fetchOnlinePayMethodList();
  },
  methods: {
    ...mapActions([
      'fetchOnlinePayMethodList',
    ]),
    payNow() {
      this.$emit('confirm', this.form.payMethod);
    }
  }
}
</script>