<template>
  <div v-if="order" class="d-flex flex-column h-100 px-2">
    <div class="d-flex align-center justify-center mb-3">
      <!-- <v-icon class="mr-2" color="var(--primary-black-color)" size="50">mdi-check-circle-outline</v-icon> -->
      <div class="page-title" :class="{ 'remove-after': textAnimationShow }">Pending Payment</div>
    </div>
    <div class="order-wrap pa-3 mb-4">
      <div class="d-flex justify-space-between mb-2">
        <div class="font-1dot25r font-weight-bold">Order No.</div>
        <div class="font-1dot25r font-weight-bold">{{ order.cdate ? setTimeZone(order.cdate, timeFormat) : '-' }}</div>
      </div>
      <v-divider class="my-2"></v-divider>

      <ReceiptSummary :order="order" />
    </div>
    <div class="order-wrap px-2 py-4 mb-6">
      <div class="font-1dot25r font-weight-bold text-center mb-2">
        <div>Your payment is being processed.</div>
        <div>It usually takes about 5 to 10 mins.</div>
      </div>
      <div class="font-1dot5r font-weight-bold font-red-common text-center">
        * Please stay on this page. *
      </div>
    </div>
    <div class="mb-3">
      <AppBtn class="btn-black mb-2" :loading="loading" block @click="getOrderStatus">Check Payment Status</AppBtn>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { orderApi } from '@/api/order';
import { ORDER_STATUS, PG_USER_TX_STATUS } from '@/common/enum/order';
import { DELIVERY_CHANNEL } from '@/common/enum/checkout';
import ReceiptSummary from '@/views/Order/receipt-summary';
export default {
  name: 'OrderPaymentPending',
  components: {
    ReceiptSummary,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      textAnimationShow: true,
      // detail: {
      //   deliveryFee: 0,
      //   amt: 0,
      // },
    };
  },
  computed: {
    ...mapState({
      timeFormat: state => state.app.timeFormat,
    }),
    ...mapGetters(['getOrderHistory', 'getOrderUserId']),
    isDelivery() {
      return this.order?.channel == DELIVERY_CHANNEL.DELIVERY;
    },
    subtotal() {
      if (this.isUndefinedNullOrEmpty(this.order.deliveryFee)) return this.priceFormat(this.order.amt);
      const nettDeliveryFee = this.order.deliveryFee - this.order.discount;
      return this.priceFormat(this.order.amt - nettDeliveryFee);
    },
    deliveryFee() {
      return !this.isUndefinedNullOrEmpty(this.order.deliveryFee)
        ? this.priceFormat(this.order.deliveryFee)
        : this.priceFormat(0);
    },
    deliveryFeeAfterDisc() {
      if (this.isUndefinedNullOrEmpty(this.order.discount)) return this.deliveryFee;
      return this.priceFormat(this.deliveryFee - this.order.discount);
    },
  },
  created() {
    // this.getDetail();
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE']),
    ...mapActions(['updateOrderHistory']),
    getOrderStatus() {
      const params = {
        id: this.$route.query.orderId,
        userId: this.getOrderUserId,
      };
      this.loading = true;
      this.textAnimationShow = false;

      return orderApi
        .getSeq(params)
        .then(res => {
          this.loading = false;
          this.textAnimationShow = true;
          const data = res.data;

          if (data.status == ORDER_STATUS.CONFIRMED) {
            let order = this.getOrderHistory.find(item => item.orderId == this.$route.query.orderId);
            order.seq = data.seq;
            order.status = data.status;
            order.curSeq = data.curSeq;

            this.updateOrderHistory(order);
            this.navigateReplace('OrderPayment', {}, { ...this.$route.query, status: PG_USER_TX_STATUS.ACTIVE });
          } else if (data.status == ORDER_STATUS.FAILED) {
            let order = this.getOrderHistory.find(item => item.orderId == this.$route.query.orderId);
            order.status = data.status;

            this.updateOrderHistory(order);
            this.navigateReplace('OrderPayment', {}, { ...this.$route.query, status: PG_USER_TX_STATUS.FAILED });
          }
        })
        .catch(() => {
          this.loading = false;
          this.textAnimationShow = true;
        });
    },
    // getDetail() {
    //   const params = {
    //     id: this.order.orderId,
    //     userId: this.order.userId,
    //   }
    //   return orderApi.detail(params).then(res => {
    //     this.detail = res.data;
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
.page-title {
  color: var(--primary-black-color);
  font-size: 1.75rem;
  font-weight: 600;

  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }

  &.remove-after:after {
    content: none;
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
.order-wrap {
  background-color: #fff;
  border: 5px solid var(--primary-black-color);
  border-radius: 5px;
}
</style>
