<template>
  <form ref="form" method="POST" :action="data.actionUrl" role="molpayseamless">
    <input type="hidden" name="channel" :value="data.channel" />
    <input type="hidden" name="currency" id="currency" :value="data.currency" />
    <input type="hidden" name="country" :value="data.country" />
    <input type="hidden" name="amount" :value="data.amount" />
    <input type="hidden" name="orderid" :value="data.orderId" />
    <input type="hidden" name="merchant_id" :value="data.merchantId" />
    <input type="hidden" name="vcode" :value="data.vcode" />
    <input type="hidden" name="bill_name" :value="data.billName" />
    <input type="hidden" name="bill_email" :value="data.billEmail" />
    <input type="hidden" name="bill_mobile" :value="data.billMobile" />
    <input type="hidden" name="bill_desc" :value="'BaogerLab'" />

    <!-- <input type="hidden" name="molpaytimer" value="3" /> -->

    <!-- <input type = "hidden" name = "returnurl" :value="data.returnurl" />
    <input type = "hidden" name = "callbackurl" :value="data.callbackurl" /> -->
    <input type="hidden" name="cancelurl" :value="'/menu/all'" />
  </form>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    }
  },
  methods: {
    postToPg() {
      this.$refs.form.submit();
    },
  }
}
</script>