<template>
  <div class="h-100">
    <div v-if="ready">
      <Success v-if="isSuccess" :order="orderRes" />
      <Failed v-else-if="isFailed" :order="orderRes" />
      <Pending v-else-if="isPending" :order="orderRes" />
    </div>
    <v-overlay :value="overlay" :z-index="500">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Success from './components/success';
import Failed from './components/failed';
import Pending from './components/pending';
import { orderApi } from '@/api/order';
import { ORDER_STATUS, PG_USER_TX_STATUS } from '@/common/enum/order';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  components: {
    Success,
    Failed,
    Pending,
  },
  data() {
    return {
      // order: {},
      orderId: null,
      status: null,
      ready: false,

      orderRes: {},
      // orderFound: false,

      overlay: true,
    };
  },
  computed: {
    ...mapState({
      defaultPageAfterLogin: state => state.app.defaultPageAfterLogin,
    }),
    ...mapGetters(['getOrderHistory', 'getOrderUserId']),
    isPending() {
      return this.status == PG_USER_TX_STATUS.PENDING;
    },
    isSuccess() {
      return this.status == PG_USER_TX_STATUS.ACTIVE;
    },
    isFailed() {
      return this.status == PG_USER_TX_STATUS.FAILED;
    },
    isInvalidEntry() {
      const query = this.$route.query;
      return (
        !query ||
        !query.orderId ||
        !query.status ||
        (query.status != PG_USER_TX_STATUS.PENDING &&
          query.status != PG_USER_TX_STATUS.ACTIVE &&
          query.status != PG_USER_TX_STATUS.FAILED)
      );
    },
  },
  watch: {
    $route(route) {
      this.status = route.query.status;
      // this.order = this.getOrderHistory.find(item => item.orderId == this.orderId);
    },
  },
  // beforeDestroy() {
  //   this.SET_ORDER_USER_ID(null);
  // },
  async created() {
    if (this.isInvalidEntry) {
      this.navigateReplace(this.defaultPageAfterLogin);
    } else {
      const query = this.$route.query;
      this.orderId = query.orderId;
      this.status = query.status;

      // this.order = this.getOrderHistory.find(item => item.orderId == this.orderId);

      // this.orderFound = !this.isUndefinedNullOrEmpty(this.order) ? true : false;

      this.overlay = true;

      this.orderRes = await this.getDetail(this.orderId, this.getOrderUserId);

      if (this.isUndefinedNullOrEmpty(this.orderRes)) {
        this.showError(this.$t('msg.error.recordNotFound'));
        this.redirectToMenu();
        return;
      }

      if (query.status == PG_USER_TX_STATUS.ACTIVE) {
        this.processSuccess(this.orderRes);
      } else if (query.status == PG_USER_TX_STATUS.FAILED) {
        this.processFailed(this.orderRes);
      } else if (query.status == PG_USER_TX_STATUS.PENDING) {
        this.processPending(this.orderRes);
      }

      this.clearCart();
      this.clearPlacedCart();

      this.clearFocCart();
      this.clearPlacedFocCart();
    }
  },
  methods: {
    ...mapMutations(['SET_ORDER_USER_ID']),
    ...mapActions(['setOrderHistory', 'updateOrderHistory', 'clearCart', 'clearPlacedCart', 'clearFocCart', 'clearPlacedFocCart']),
    processSuccess(order) {
      if (!this.isUndefinedNullOrEmpty(order) && order.status == ORDER_STATUS.CONFIRMED) {
        // if (this.orderFound) {
        // this.order.seq = order.seq;
        // this.order.status = order.status;
        // this.order.curSeq = order.curSeq;

        // this.updateOrderHistory(this.order);
        this.setHistory(order);
        // }
        this.overlay = false;
        this.ready = true;
      } else {
        this.showError(this.$t('msg.error.recordNotFound'));
        this.redirectToMenu();
      }
    },
    processFailed(order) {
      if (!this.isUndefinedNullOrEmpty(order) && order.status == ORDER_STATUS.FAILED) {
        // if (this.orderFound) {
        // this.order.status = order.status;

        // this.updateOrderHistory(this.order);
        this.setHistory(order);
        // }
        this.overlay = false;
        this.ready = true;
      } else {
        this.showError(this.$t('msg.error.recordNotFound'));
        this.redirectToMenu();
      }
    },
    processPending(order) {
      if (!this.isUndefinedNullOrEmpty(order) && order.status == ORDER_STATUS.PENDING_PAYMENT) {
        // if (this.orderFound) {
        // this.order.status = order.status;

        // this.updateOrderHistory(this.order);
        this.setHistory(order);
        // }
        this.overlay = false;
        this.ready = true;
      } else {
        this.showError(this.$t('msg.error.recordNotFound'));
        this.redirectToMenu();
      }
    },
    // getOrderSeq(orderId, userId) {
    //   const params = {
    //     id: orderId,
    //     userId,
    //   }
    //   return orderApi.getSeq(params).then(res => {
    //     return res;
    //   });
    // },
    getDetail(orderId, userId) {
      const params = {
        id: orderId,
        userId,
      };
      return orderApi.detail(params).then(res => {
        if (this.isUndefinedNullOrEmpty(res.data)) {
          this.showError(this.$t('msg.error.recordNotFound'));
          return this.redirectToMenu();
        }
        return res.data;
      });
    },
    redirectToMenu() {
      setTimeout(() => {
        this.overlay = false;
        this.navigateReplace('MenuAll');
      }, 1500);
    },
    setHistory(data) {
      const orderData = {
        amt: data.amt,
        deliveryFee: data.deliveryFee,
        orderId: data.id,
        seq: data.seq,
        curSeq: data.curSeq,
        userId: this.getOrderUserId,
        cdate: data.cdate,
        items: data.items,
        cancelled: 0,
        status: data.status,
        channel: data.channel,
        storeName: data.store,
        locName: '',
      };
      const histObj = this.getOrderHistory.find(item => item.orderId == orderData.orderId);

      if (this.isUndefinedNullOrEmpty(histObj)) {
        this.setOrderHistory(orderData);
      } else {
        this.updateOrderHistory(orderData);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
