<template>
  <div v-if="order" class="d-flex flex-column h-100 px-2">
    <div class="d-flex align-center justify-center mb-3">
      <v-icon class="mr-2" color="var(--primary-black-color)" size="50">mdi-check-circle-outline</v-icon>
      <div class="page-title">Payment Success</div>
    </div>
    <div class="order-wrap pa-3 mb-3">
      <template v-if="isTakeaway">
        <div class="d-flex justify-space-between">
          <div class="font-1dot25r font-weight-bold">Order No.</div>
          <div class="font-1dot25r font-weight-bold">{{ order.cdate ? setTimeZone(order.cdate, timeFormat) : '-' }}</div>
        </div>
        <div class="font-3dot5r font-weight-bold text-center my-3">
          {{ order.seq }}
        </div>
        <div class="font-1r font-weight-bold text-center mb-2">
          Current queue: {{ !isUndefinedNullOrEmpty(order.curSeq) ? order.curSeq : '-' }}
        </div>
        <div class="font-1dot25r font-weight-bold text-center mb-1">
          Your meal is being prepared.
        </div>
        <div class="font-1dot25r font-weight-bold text-center">
          Please wait for your turn to collect.
        </div>
      </template>
      <template v-else-if="isPickUp">
        <div class="d-flex justify-space-between">
          <div class="font-1dot25r font-weight-bold">Order No.</div>
          <div class="font-1dot25r font-weight-bold">{{ order.cdate ? setTimeZone(order.cdate, timeFormat) : '-' }}</div>
        </div>
        <div class="font-3dot5r font-weight-bold text-center my-3">
          {{ order.seq }}
        </div>
        <template v-if="isUndefinedNullOrEmpty(order.doDate)">
          <div class="font-1dot1r font-weight-bold text-center mb-2">
            {{ `${$t('pickup.lbl.pickupTime')}: ${$t('lbl.now')}` }}
          </div>
        </template>
        <template v-else>
          <div class="font-1dot1r font-weight-bold text-center mb-2">
            {{ `${$t('pickup.lbl.pickupTime')}: `
            }}<span class="font-red-common">{{ pickUpTime ? formatTime(pickUpTime) : '-' }}</span>
          </div>
        </template>
        <div class="font-1dot1r font-weight-bold text-center mb-1">
          Please show this receipt and collect your order at
        </div>
        <div class="font-1dot1r font-weight-bold text-center" style="color: var(--red-common)">
          {{ order.loc }}
        </div>
      </template>
      <template v-else-if="isDelivery">
        <div class="d-flex justify-space-between">
          <div class="font-1dot25r font-weight-bold">Order No.</div>
          <div class="font-1dot25r font-weight-bold">{{ order.cdate ? setTimeZone(order.cdate, timeFormat) : '-' }}</div>
        </div>
        <div class="font-3dot5r font-weight-bold text-center my-3">
          {{ order.seq }}
        </div>
        <template v-if="isUndefinedNullOrEmpty(order.doDate)">
          <div class="font-1dot1r font-weight-bold text-center mb-2">
            Your order is being prepared.
          </div>
          <div class="font-1dot1r font-weight-bold text-center mb-1">
            We will deliver to your doorstep soon.
          </div>
        </template>
        <template v-else>
          <!-- <div class="font-1dot1r font-weight-bold text-center mb-2">
            {{ $t('checkout.lbl.orderInAdvance') }}
          </div> -->
          <div class="font-1dot1r font-weight-bold text-center mb-1">
            <div class="mb-1">
              <span>Delivery at </span>
              <span class="font-red-common">{{ order.doDate ? formatTime(order.doDate) : '-' }}</span>
            </div>
            <div class="mb-1">
              <span>by </span>
              <span class="font-red-common">{{ deliveryChannelMap[order.deliveryId] }}</span>
            </div>
            <!-- Please expect your order to be delivered at
            <span class="font-red-common font-1dot1r">{{  }}</span> by
            <span class="font-red-common font-1dot1r">{{ deliveryChannelMap[order.deliveryId] }}</span> -->
          </div>
          <div></div>
        </template>
      </template>
    </div>

    <!-- <div class="order-wrap pa-3 mb-4">
      <div class="d-flex justify-space-between">
        <div class="font-1dot25r font-weight-bold mb-2">Delivery Info</div>
      </div>
      <div class="font-1dot25r font-weight-bold mb-3">
        {{ `${detail.address} ${detail.postcode} ${detail.city}` }}
      </div>
    </div> -->

    <div class="order-wrap pa-3">
      <ReceiptSummary :order="order" />
    </div>
    <!-- <div class="mt-4 mb-2 font-1dot25r"><a href="#">Sign up</a> now to enjoy more benefits</div> -->
    <div class="my-3">
      <AppBtn class="btn-black" block :height="42" @click="orderAgain">{{ 'Order Again' }}</AppBtn>
    </div>
  </div>
</template>
<script>
import { DELIVERY_CHANNEL, SCHEDULE_TYPE } from '@/common/enum/checkout';
import { mapState, mapMutations, mapActions } from 'vuex';
import ReceiptSummary from '@/views/Order/receipt-summary';
// import { orderApi } from '@/api/order';

export default {
  name: 'OrderPaymentSuccess',
  components: {
    ReceiptSummary,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      detail: {
        // address: '',
        // postcode: '',
        // city: '',
        deliveryFee: 0,
        amt: 0,
      },
    };
  },
  computed: {
    ...mapState({
      timeFormat: state => state.app.timeFormat,
      deliveryChannelMap: state => state.lookup.deliveryChannelMap,
    }),
    isTakeaway() {
      return this.order.channel == DELIVERY_CHANNEL.TAKEAWAY;
    },
    isPickUp() {
      return this.order.channel == DELIVERY_CHANNEL.PICK_UP;
    },
    isDelivery() {
      return this.order.channel == DELIVERY_CHANNEL.DELIVERY;
    },
    pickUpTime() {
      if (this.isUndefinedNullOrEmpty(this.order.doDate)) {
        return this.$t('lbl.now');
      } else {
        return this.order.doDate;
      }
    },
    SCHEDULE_TYPE() {
      return SCHEDULE_TYPE;
    },
  },
  created() {
    // this.SET_DRAWER_TITLE('Order Detail');
    if (this.isUndefinedNullOrEmpty(this.deliveryChannelMap)) this.fetchDeliveryChannelList();
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE']),
    ...mapActions(['fetchDeliveryChannelList']),
    orderAgain() {
      this.navigatePush('MenuAll');
    },
    formatTime(dateTime) {
      return this.setTimeZone(dateTime, 'ddd, D MMM, hh:mm A');
    },
  },
};
</script>
<style lang="scss" scoped>
.page-title {
  color: var(--primary-black-color);
  font-size: 1.5rem;
  font-weight: 600;
}
.order-wrap {
  background-color: #fff;
  border: 5px solid var(--primary-black-color);
  border-radius: 5px;
}
</style>
